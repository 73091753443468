import React, { Suspense, lazy, useState, useEffect, useContext } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Button, Col, Container, Form, Navbar } from "react-bootstrap";
import cx from "classnames";
import { History } from "history";

import ReactResizeDetector from "react-resize-detector";

import Main from "./layout/main";

import { StoreState } from "./components/redux/types/store.type";

import LoginBase from "./components/userPages/Login";

import { auth } from "./components/firebase";

import {
  ThemeOptionsState,
  ThemeOptionsAction,
} from "./components/redux/types/themeOptions.type";

import { Routes, Route, Link } from "react-router-dom";
import TutorialsList from "./components/tutorial/tutorials-list.component";
import ArticlesList from "./components/tutorial/articles-list.component";
import { AuthContext } from "./components/session/context/auth.context";

import Dashboards from "./components/pages/dashboards";
// const Dashboards = lazy(() => import("./components/pages/dashboards"));

import CHILLDashboard from "./components/pages/dashboards/CHILL";
import FCUDashboard from "./components/pages/dashboards/FCU";
import AHUDashboard from "./components/pages/dashboards/AHU";
import ENERGYDashboard from "./components/pages/dashboards/ENERGY/";
import METRICSDashboard from "./components/pages/dashboards/METRICS/";
import METERDashboard from "./components/pages/dashboards/METER/";
import BMSALARMDashboard from "./components/pages/dashboards/BMSALARM/";
import BMSSCHEDDashboard from "./components/pages/dashboards/BMSSCHED/";
import ACUDashboard from "./components/pages/dashboards/ACU/";
import MVHRDashboard from "./components/pages/dashboards/MVHR/";
import BIMODELDashboard from "./components/pages/dashboards/BIMODEL/";
import CTRLDashboard from "./components/pages/dashboards/CTRL/";

import LIGHTKEYDashboard from "./components/pages/dashboards/LIGHTKEY/";
import LIGHTCTRLDashboard from "./components/pages/dashboards/LIGHTCTRL/";
import LIGHTAREACTRLDashboard from "./components/pages/dashboards/LIGHTAREACTRL/";
import ETSDashboard from "./components/pages/dashboards/ETS/";
import MTSDashboard from "./components/pages/dashboards/MTS/";
import QSEDashboard from "./components/pages/dashboards/QSE/";

import NETVOX3PHASEDashboard from "./components/pages/dashboards/NETVOX3PHASE/";

import LUMINAIREDashboard from "./components/pages/dashboards/LUMINAIRE/";


import SPACEDashboard from "./components/pages/dashboards/SPACE/";
import AIRDashboard from "./components/pages/dashboards/AIR/";
import BIMDashboard from "./components/pages/dashboards/BIM/";
import GraphicEditor from "./components/pages/dashboards/admin/graphic-editor";

type MapStateToPropsType = {
  ThemeOptions: ThemeOptionsState;
};

type Props = ConnectedProps<typeof connector> & MapStateToPropsType;

const App: React.FC<Props> = ({ ThemeOptions }) => {
  const [closedSmallerSidebar, setClosedSmallerSidebar] =
    useState<boolean>(false);

  const signOut = async () => {
    await auth.signOut();
  };

  // Set your desired timeout duration (in milliseconds)
  const TIMEOUT_DURATION = 12 * 60 * 60 * 1000; // 12 x 60 minutes = 12 hours

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  // Clear timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  // Start/reset the timeout on user activity
  const resetTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      // Perform sign out action here
      signOut();
      console.log("User has been signed out due to inactivity.");
    }, TIMEOUT_DURATION);
    setTimeoutId(newTimeoutId);
  };

  const user = useContext(AuthContext);
  // const devMode = true;//for use when developing without internet

  // Listen to user activity events
  useEffect(() => {
    document.addEventListener("keydown", resetTimeout);
    document.addEventListener("click", resetTimeout);
    return () => {
      document.removeEventListener("keydown", resetTimeout);
      document.removeEventListener("click", resetTimeout);
    };
  }, []);

  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width, height, targetRef }) => (
        <>
          <div
            ref={targetRef as React.RefObject<HTMLDivElement> | null}
            className={cx(
              "app-container app-theme-" + ThemeOptions.colorScheme,
              { "fixed-header": ThemeOptions.enableFixedHeader },
              {
                "fixed-sidebar":
                  ThemeOptions.enableFixedSidebar || (width && width < 1250),
              },
              { "fixed-footer": ThemeOptions.enableFixedFooter },
              {
                "closed-sidebar":
                  ThemeOptions.enableClosedSidebar || (width && width < 1250),
              },
              {
                "closed-sidebar-mobile":
                  closedSmallerSidebar || (width && width < 1250),
              },
              { "sidebar-mobile-open": ThemeOptions.enableMobileMenu },
              { "body-tabs-shadow-btn": ThemeOptions.enablePageTabsAlt }
            )}
          >
            <>
            {/* !devMode interchange with !user when developing without internet  */}
              {!user ? (
                <>
                  {/* <Main />  */}
                  <LoginBase />
                </>
              ) : (
                <>
                  {/* <Navbar className="justify-content-between" bg="dark" variant="dark">
                  <Navbar.Brand>Firebase Authentication</Navbar.Brand>
                  {user && <Button onClick={signOut}>Sign Out</Button>}
                </Navbar>
                <h2 className="mt-4 text-center">Welcome {user.email}</h2>
                <nav className="navbar navbar-expand navbar-dark bg-dark">
                  <Link to={"/dashboards"} className="navbar-brand">
                    dashboards
                  </Link>
                  <div className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <Link to={"/tutorials"} className="nav-link">
                        Tutorials
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/articles"} className="nav-link">
                        Articles
                      </Link>
                    </li>
                  </div>
                </nav> */}
                  <div>
                    <Routes>
                      <Route path="dashboards" element={<Dashboards user={user}/>}>
                        <Route index element={<LIGHTAREACTRLDashboard />} />
                        <Route path={`ahu`} element={<AHUDashboard />} />
                        <Route path={`fcu`} element={<FCUDashboard />} />
                        <Route path={`chill`} element={<CHILLDashboard />} />
                        <Route path={`meter`} element={<METERDashboard />} />

                        <Route path={`acu`} element={<ACUDashboard />} />
                        <Route path={`mvhr`} element={<MVHRDashboard />} />
                        
                        <Route path={`energy`} element={<ENERGYDashboard />} />
                        <Route path={`metrics`} element={<METRICSDashboard />} />
                        <Route path={`space`} element={<SPACEDashboard />} />
                        <Route path={`air`} element={<AIRDashboard />} />
                        <Route path={`bim`} element={<BIMDashboard />} />

                        <Route path={`luminaire`} element={<LUMINAIREDashboard />} />
                        <Route path={`bimodel`} element={<BIMODELDashboard />} />
                        <Route path={`ctrl`} element={<CTRLDashboard />} />
                        <Route path={`lightctrl`} element={<LIGHTCTRLDashboard />} />
                        <Route path={`lightareactrl`} element={<LIGHTAREACTRLDashboard />} />
                        <Route path={`sensor`} element={<MTSDashboard />} />
                        <Route path={`envsensor`} element={<ETSDashboard />} />
                        <Route path={`spq7`} element={<QSEDashboard />} />
                        <Route path={`lightkeypad`} element={<LIGHTKEYDashboard />} />

                        <Route path={`netvox3phase`} element={<NETVOX3PHASEDashboard />} />

                        <Route path={`bmsalarm`} element={<BMSALARMDashboard />} />
                        <Route path={`bmssched`} element={<BMSSCHEDDashboard />} />

                        <Route
                          path={`admin/graphic-editor`}
                          element={<GraphicEditor />}
                        />
                      </Route>
                      <Route path="/" element={<Dashboards user={user}/>} />
                      <Route
                        path="tutorials"
                        element={<TutorialsList user={user} />}
                      />
                      <Route
                        path="articles"
                        element={
                          <ArticlesList
                            user={user}
                            fetchArticles={() => undefined}
                            saveArticle={() => undefined}
                          />
                        }
                      />
                    </Routes>
                  </div>
                </>
              )}
            </>
          </div>
        </>
      )}
    </ReactResizeDetector>
  );
};

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
  return {
    ThemeOptions: state.ThemeOptions,
  };
};

const connector = connect(mapStateToProps);

export default connector((props: Props) => {
  return <App {...props} />;
});
