import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import './styles.css'

import CountUp from "react-countup";

import styled from 'styled-components'

import ReactGaugeMeter from 'react-gauge-meter'; 

import { BuildingState } from '../../../../redux/types/building.type';

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import { IExtendedEnergyData, IEnergyData } from "../../../../firebase/types/energy.type";

import LightControlModal from "../../../components/luminaireModal/modal";

  type Props = {
    buildings: BuildingState
  };
  
  type State = {
      dropdownOpen: false;
      selectedOption: null;
      loading: false;
      device: [];
      modalShow: boolean;
      brightness: number;
      deviceIdSelected: string;
  };
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {
    private ws: WebSocket | null = null;

    constructor(props: Props) {
      super(props);

      this.state = {
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        modalShow: false,
        brightness: 44,
        deviceIdSelected:"LT-000131"
      };

    }

    componentDidMount() {
      this.connectWebSocket();
    }
  
    componentWillUnmount() {
      if (this.ws) {
        this.ws.close();
      }
    }

    setModalShow = (visible: boolean) => {
      this.setState({ modalShow: visible });
    }
  
    setBrightness = (brightness: number) => {
      this.setState({ brightness });
    }

    handleAsset(input: string): void {
      // Extract the prefix by splitting the input string by '-' and taking the first part
      const prefix = input.split('-')[0];
    
      switch (prefix) {
        case 'MVHR':
          console.log('Handling MVHR prefix');
          // Add more specific code to handle MVHR cases here
          break;
        case 'ACU':
          console.log('Handling ACU prefix');
          // Add more specific code to handle ACU cases here
          break;
        case 'LT':
          console.log('Handling LT prefix');
          // Add more specific code to handle LT cases here
          break;
        default:
          console.log('No specific handling for this prefix');
          // Code to handle cases where none of the prefixes match
          break;
      }
    }
    
    connectWebSocket = () => {
      // Assuming the WebSocket URL is ws://34.38.77.8
      // this.ws = new WebSocket('ws://localhost:8080');
      this.ws = new WebSocket('wss://bim.thaniumos.com');
  
      this.ws.onopen = () => {
        console.log('REACT WebSocket Connected');
        // You can send messages to the server here using this.ws.send(data)
      };
  
      this.ws.onerror = (error) => {
        console.log('REACT WebSocket Error: ', error);
      };
  
      this.ws.onmessage = (e: MessageEvent) => {
        // Check if the received data is a Blob
        if (e.data instanceof Blob) {
          // Create a FileReader to read the Blob
          const reader = new FileReader();
          
          // Define what happens on successful data read
          reader.onload = () => {
            // This will log the text content that was read
            const text = reader.result as string;
            console.log('REACT Blob WebSocket message received:', text);
            // this.handleAsset(text);
            let deviceName = text;
            if(deviceName.includes('-') && (deviceName.split('-')[0] === 'LT')){
              this.setState({ deviceIdSelected: deviceName });
              this.setModalShow(true)
            }
          };
      
          // Define what happens in case of an error
          reader.onerror = (error) => {
            console.error('Error reading the Blob data:', error);
          };
      
          // Start reading the Blob as text
          reader.readAsText(e.data);
        } else {
          // If it's not a Blob, assume it's already text (or handle other cases as needed)
          console.log('REACT !Blob WebSocket message received:', e.data);
          let deviceName = e.data;
          if(deviceName.includes('-') && (deviceName.split('-')[0] === 'LT')){
            this.setState({ deviceIdSelected: deviceName });
            this.setModalShow(true)
          }
          
        }
      };
  
      this.ws.onclose = (e) => {
        console.log('REACT WebSocket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(() => {
          this.connectWebSocket();
        }, 1000);
      };
    }

      render() {
        //localhost:8080
        return (
          <>
          <div className="modal-overlay" onClick={() => this.setModalShow(false)}>
            {/* <Button onClick={() => this.setModalShow(true)}>Adjust Lighting</Button> */}
            
            <iframe 
                src="https://bim.thaniumos.com"
                title="BIM Iframe"
                width="100%"
                className="iframe-full-height"
                frameBorder="0"  // This removes the default border around the iframe. Most websites use this to make the iframe look cleaner.
                allowFullScreen  // This allows the iframe content to be viewed in full screen
            >
<LightControlModal
              show={this.state.modalShow}
              onHide={() => this.setModalShow(false)}
              brightness={this.state.brightness}
              setBrightness={this.setBrightness}
              level={'Level-01'}
              deviceIdSelected={this.state.deviceIdSelected}
            />

            </iframe>
          </div>
          </>
        );
    }

    
}