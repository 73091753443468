import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Dropdown,
  ButtonGroup
} from "react-bootstrap";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { VictoryChart, VictoryLine, VictoryAxis, VictoryTooltip, VictoryVoronoiContainer, VictoryLegend } from 'victory';

import CountUp from "react-countup";

import styled from 'styled-components'

import ReactGaugeMeter from 'react-gauge-meter'; 
import { useTable, Column, useSortBy } from "react-table";

import METER_TYPE_1 from '../../BMSComponents/components/meter_type_1';

// import { makeNewData, getBrickschema} from "../../../BMSComponents/utils";

import { Sparklines, SparklinesCurve } from "react-sparklines-typescript";

import LuminaireDataService from "../../../../firebase/services/luminaire.service";
import CallbackQueue from "../../../../firebase/services/callback.service";

import { IExtendedEnergyData, IEnergyData } from "../../../../firebase/types/energy.type";

import {IAcuData, IAcuMetaData, ExtendedIAcuData} from '../../../../firebase/types/acu.type';
import {AcuChartData, AcuChartDisplay, DeviceName} from '../../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen } from '../../../../utils/timestamp';
import AcuFilterData from '../../../components/acuFilterData';

import { BuildingState } from '../../../../redux/types/building.type';

const makeNewData: IExtendedEnergyData =
      {
        meta: {
          name: { present_value: "Energy" },
          timestamp: { present_value: "2023-03-28T09:30:00Z" },
          alarm: { present_value: "Healthy" },
        },
        metering:[
        {
          name:"CO-2",
          energy_use:{
            percent:{present_value: 42},
            kw:{present_value:139}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
        {
          name:"CO-3",
          energy_use:{
            percent:{present_value: 35},
            kw:{present_value:126}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
         {
          name:"CO-4",
          energy_use:{
            percent:{present_value: 0},
            kw:{present_value:0}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
         {
          name:"CO-5",
          energy_use:{
            percent:{present_value: 0},
            kw:{present_value:0}},
          max_capacity:{present_value: 300},
          current_target:{present_value: 140}
        },
        {
          name:"TX-14",
          energy_use:{
            percent:{present_value: 9},
            kw:{present_value:197.1}},
          max_capacity:{present_value: 2000},
          current_target:{present_value: 1150}
        },
        {
          name:"TX-15",
          energy_use:{
            percent:{present_value: 57},
            kw:{present_value:1147.4}},
          max_capacity:{present_value: 2000},
          current_target:{present_value: 1150}
        }
        ]
      }

function Meter_Type_1_DeviceHolder () {
  let data = {
    "chiller_removed_from_sequence": {
      "present_value":  "false"
    },
    "chiller_enable_status": {
      "present_value":  "false"
    },
    "isolation_valve_failed": {
      "present_value":  "false"
    },
    "percentage_total_capacity": {
      "present_value":  0
    },
    "entering_water_temp": {
      "present_value":  9.78
    },
    "leaving_water_temp": {
      "present_value":  10.06
    },
    "chiller_shutdown_alarm": {
      "present_value":  "false"
    },
    "isolation_valve_open": {
      "present_value":  "false"
    },
    "isolation_valve_enable": {
      "present_value":  "false"
    },
    "chiller_enabled": {
      "present_value":  "false"
    },
    "chiller_required": {
      "present_value":  "false"
    },
    "status": {
      "present_value":  "true"
    },
    "partial_alarm": {
      "present_value":  "false"
    },
    "shutdown_alarm": {
      "present_value":  "false"
    },
    "suction_pressure_a": {
      "present_value":  316.59
    },
    "suction_pressure_b": {
      "present_value":  325.01
    },
    "discharge_pressure_a": {
      "present_value":  344.51
    },
    "discharge_pressure_b": {
      "present_value":  349.78
    },
    "machine_operation_hours": {
      "present_value":  16903
    },
    "machine_starts": {
      "present_value":  33865
    },
    "pump_duty": {
      "present_value":  2
    },
    "pump_flow_proving": {
      "present_value":  "false"
    },
    "compressor_a_hours_run": {
      "present_value":  8561
    },
    "compressor_a_starts": {
      "present_value":  17926
    },
    "compressor_b_hours_run": {
      "present_value":  10897
    },
    "compressor_b_starts": {
      "present_value":  17693
    },
    "chiller_fan_a1_hours_run": {
      "present_value":  8486.25
    },
    "chiller_fan_a2_hours_run": {
      "present_value":  8185.25
    },
    "chiller_fan_a3_hours_run": {
      "present_value":  7747.5
    },
    "chiller_fan_a4_hours_run": {
      "present_value":  7035.75
    },
    "chiller_fan_a5_hours_run": {
      "present_value":  6574.75
    },
    "chiller_fan_a6_hours_run": {
      "present_value":  6012.25
    },
    "chiller_fan_a7_hours_run": {
      "present_value":  8681.75
    },
    "chiller_fan_b1_hours_run": {
      "present_value":  10818
    },
    "chiller_fan_b2_hours_run": {
      "present_value":  10420.5
    },
    "chiller_fan_b3_hours_run": {
      "present_value":  9761.75
    },
    "chiller_fan_b4_hours_run": {
      "present_value":  9155.25
    },
    "chiller_fan_b5_hours_run": {
      "present_value":  9869.5
    }
  };
return (
  
      <div className='meter-holder'>

        <METER_TYPE_1 data={data}/>

      </div>

);
}

  function boxMullerRandom() {
    let phase = true,
      x1,
      x2,
      w;
  
    return (function () {
      if (phase) {
        do {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        } while (w >= 1.0);
  
        w = Math.sqrt((-2.0 * Math.log(w)) / w);
        return x1 * w;
      } else {
          x1 = 2.0 * Math.random() - 1.0;
          x2 = 2.0 * Math.random() - 1.0;
          w = x1 * x1 + x2 * x2;
        return x2 * w;
      }
    })();
  }

  interface ReactTableProps<T extends object> {
    data: T[];
    columns: Column<T>[];
  }



  function ReactTable<T extends object>({ data, columns }: ReactTableProps<ExtendedIAcuData>) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
    } = useTable({
      columns,
      data,
    })
  
    // Render the UI for your table
    return (
      <table {...getTableProps()} style={{ borderCollapse: 'collapse', width: '100%', border: '1px solid grey' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ borderBottom: '2px solid grey' }}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '5px',
                    backgroundColor: '#545cd8',
                    border: '1px solid grey',
                    color: 'white',
                    textAlign: 'center'
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ borderBottom: rowIndex !== rows.length - 1 ? '1px solid grey' : undefined }}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '5px',
                        backgroundColor: cellIndex % 2 === 0 ? 'white' : 'lightgrey',
                        textAlign: 'center'
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    )
    
    
  }
  
  function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
  }
  
  const sampleData = randomData(10);
  const sampleData2 = randomData(15);
  const sampleData3 = randomData(8);
  const sampleData4 = randomData(12);

  const Styles = styled.div`
    padding: 1rem;

    fontSize: 0.95em;
    height: 450px; // This will force the table body to overflow and scroll, since there is not enough room

    table {
        border-spacing: 0;
        border: 1px solid black;

        tr {
        :last-child {
            td {
            border-bottom: 0;
            }
        }
        }

        th,
        td {
        margin: 0;
        padding: 0.5rem;
        border-bottom: 1px solid black;
        border-right: 1px solid black;

        :last-child {
            border-right: 0;
        }
        }
    }
  `
  
  type Props = {
    buildings: BuildingState
  };

  type DeviceMap = Map<string, { BuildingName: string; LevelName: string }>

  type State = {
      flattenedPointset: Array<ExtendedIAcuData>,
      metadata: Array<IAcuMetaData>,
      brickschema: {},
      dropdownOpen: false,
      selectedOption: null,
      loading: false,
      device: [],
      chartData: AcuChartData;
      chartDisplay: AcuChartDisplay;
      meteringData: IExtendedEnergyData;
      deviceMap: DeviceMap;
  };

  const callbackQueue = new CallbackQueue()
  
  export default class AnalyticsDashboard1 extends Component<Props, State> {
    unsubscribe: () => void;

    constructor(props: Props) {
      super(props);
      

      this.state = {
        flattenedPointset: [],
        metadata: [],
        brickschema: {},
        dropdownOpen: false,
        selectedOption: null,
        loading: false,
        device: [],
        chartData:[
          { timestamp: "2022-08-28T10:50:00.000Z", 
            fan_speed: 1,
            run_mode:1,
            run_status: 1,
            temperature:33.8,
            temperature_setpoint: 25.2,
            compare_fan_speed: 3,
            compare_run_mode:3,
            compare_run_status: 3,
            compare_temperature:20.8,
            compare_temperature_setpoint: 25.2,
        },
        { timestamp: "2022-09-04T08:30:00.000Z", 
            fan_speed: 2,
            run_mode:2,
            run_status: 2,
            temperature:35.8,
            temperature_setpoint: 27.2,
            compare_fan_speed: 2,
            compare_run_mode:1,
            compare_run_status: 1,
            compare_temperature:23.8,
            compare_temperature_setpoint: 25.2,
        },
        ],
        chartDisplay:{
          fan_speed: true,
          run_mode:true,
          run_status: true,
          temperature:true,
          temperature_setpoint: true,
          compare_fan_speed: false,
          compare_run_mode: false,
          compare_run_status:false,
          compare_temperature: false,
          compare_temperature_setpoint:false,
  
        },
        meteringData:makeNewData,
        deviceMap: new Map()
      };

      this.fetchTableData = this.fetchTableData.bind(this);
      this.handleFilterData = this.handleFilterData.bind(this);
      this.onDataChange = this.onDataChange.bind(this);

      this.unsubscribe = () => { };
    }
  
    componentDidUpdate(prevProps: Props) {
      if (this.props.buildings !== prevProps.buildings) {
          if (this.unsubscribe) {
              this.unsubscribe();
          }
          this.fetchTableData();
      }
    }

    componentDidMount() {
      this.fetchTableData();
    }
  
    componentWillUnmount() {
      if (this.unsubscribe) {
        this.unsubscribe();
    }
    }

    fetchTableData(){
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      let deviceMap: DeviceMap = new Map();
      let subscriptions: any = []
      for (const [BuildingName, BuildingObject] of Object.entries(this.props.buildings)) {
        for (const [LevelName, LevelObject] of Object.entries(BuildingObject)) {
          for (const deviceName of LevelObject.registries.device) {
            const pattern = /^(ACU)-\d+$/;
            if(pattern.test(deviceName)){
              deviceMap.set(deviceName, { BuildingName: BuildingName, LevelName: LevelName });
              const unsubscribe = LuminaireDataService.getDevice(LevelName, deviceName).onSnapshot((snapshot) => {
                callbackQueue.enqueue(() => {
                  this.onDataChange(snapshot, deviceName);
                  return Promise.resolve();
                });
              });
              subscriptions.push(unsubscribe);
            }
          }
        }
      }
      this.setState({deviceMap:deviceMap});
      this.unsubscribe = () => {
        for (const unsubscribe of subscriptions) {
            unsubscribe();
        }
      };
    }

    handleFilterData(chartData: AcuChartData, chartDisplay: AcuChartDisplay): Promise<void> {
      console.log('acu variation1 handleFilterData');
      return new Promise<void>((resolve) => {
        this.setState({
          chartData: chartData,
          chartDisplay: chartDisplay
        }, () => {
          resolve(); // Resolve the promise after the state has been updated
        });
      });
    }

    onDataChange(items: any, deviceName: string) {
      const { deviceMap } = this.state;
      console.log("onDataChange");
      let areaName ='-';
      let roomName ='';
      items.forEach((item: any) => {
          let id:string = item.id;
          let data = item.data();
          console.log(deviceName)
          const deviceInfo = deviceMap.get(deviceName);
          let levelName = deviceInfo ? deviceInfo.LevelName : '-';
          let buildingName = deviceInfo ? deviceInfo.BuildingName : '-';

          if(id === 'metadata'){
            console.log('metadata');
            console.log(JSON.stringify(data));
            //may need to get metadata and save to state
            let regex = /1st Floor|Ground|GF|1st/gi;
            roomName = data.LocationDescription ? data.LocationDescription.replace(regex, '').trim() : '-';
          }
          if(id === 'pointset'){
            console.log('pointset');
              // Ensure that 'data.timestamp' and 'data.timestamp.seconds' exist
              if (data.timestamp && typeof data.timestamp.seconds === 'number') {
                  const dateMillis = data.timestamp.seconds * 1000
                  const deviceDate = new Date(dateMillis)
                  
                  const isoTimestamp = deviceDate.toISOString();
                  const formattedTimestamp = createHumanTimestamp(isoTimestamp);

                  const deviceOnline = isDeviceOnline(deviceDate);
                  const lastSeen = calculateLastSeen(dateMillis);
                  const lastSeenDays = lastSeen[0];
                  const lastSeenHours = lastSeen[1];
                  const lastSeenMins = lastSeen[2];
  
                  const flattenedPoint: ExtendedIAcuData = {
                      ...data.points as any,  
                      version: data.version,
                      name: { present_value: deviceName }, 
                      level: { present_value: levelName },
                      area: { present_value: areaName },
                      room: { present_value: roomName },   
                      timestamp: { present_value: formattedTimestamp },
                      online: {present_value: deviceOnline},
                      last_seen_days: {present_value: lastSeenDays},
                      last_seen_hours: {present_value: lastSeenHours},
                      last_seen_mins: {present_value: lastSeenMins}
                  };
  
                  this.setState(prevState => {
                    const index = prevState.flattenedPointset.findIndex(point => point.name.present_value === deviceName);
                    
                    if (index !== -1) {
                        // If the device exists in the array, create a new array with updated data for that device
                        const updatedPoints = [...prevState.flattenedPointset];
                        updatedPoints[index] = flattenedPoint;
                        return {
                            ...prevState,
                            flattenedPointset: updatedPoints
                        };
                    } else {
                        // If the device doesn't exist in the array, append it
                        return {
                            ...prevState,
                            flattenedPointset: [...prevState.flattenedPointset, flattenedPoint]
                        };
                    }
                });
              } else {
                  console.error('Timestamp data is missing or in incorrect format', data);
              }
          }
      });
    }

    calculateTicks(data:AcuChartData, numTicks:number) {
      const step = Math.floor(data.length / numTicks);
      return data
        .filter((_, index) => index % step === 0)
        .map(item => item.timestamp);
    }

      render() {
        const { flattenedPointset, chartData, chartDisplay, meteringData } = this.state;

        console.log(flattenedPointset);

        const calculateMaxYValue = (chartData:AcuChartData, chartDisplay:AcuChartDisplay) => {
          if (!Array.isArray(chartData) || chartData.length === 0) {
            return 3;
          }

          return Math.max(
            ...chartData.map(d => Math.max(
              chartDisplay.fan_speed ? d.fan_speed ?? 0 : 0,
              chartDisplay.run_mode ? d.run_mode ?? 0 : 0,
              chartDisplay.run_status ? d.run_status ?? 0 : 0,
              chartDisplay.temperature ? d.temperature ?? 0 : 0,
              chartDisplay.temperature_setpoint ? d.temperature_setpoint ?? 0 : 0,
              chartDisplay.compare_fan_speed ? d.compare_fan_speed ?? 0 : 0,
              chartDisplay.compare_run_mode ? d.compare_run_mode ?? 0 : 0,
              chartDisplay.compare_run_status ? d.compare_run_status ?? 0 : 0,
              chartDisplay.compare_temperature ? d.compare_temperature ?? 0 : 0,
              chartDisplay.compare_temperature_setpoint ? d.compare_temperature_setpoint ?? 0 : 0
            ))
          );
        };

        const RenderRechart = (chartData:AcuChartData, chartDisplay:AcuChartDisplay) => {
          return(
            <ResponsiveContainer width="100%" aspect={8.0 / 3.0}>
            <LineChart data={chartData} width={500} height={300} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
              <XAxis 
                dataKey="timestamp" 
                tickFormatter={createHumanTimestamp}
                // ticks={this.calculateTicks(chartData, 7)}
                angle={-45} 
                textAnchor="end"
                height={120}
              />
              <YAxis label={{ value: "#", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} interval={0} domain={[0, calculateMaxYValue]}/> 
              {/* <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 4000]} interval={0} ticks={[0, 800, 1600, 2400, 3200, 4000]}/>  */}
              <YAxis label={{ value: "°C", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 30]} interval={0}/>
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              {chartDisplay.fan_speed ? (<Line yAxisId="left" type="monotone" dataKey="fan_speed" stroke="#6fa8dc" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.run_mode ? (<Line yAxisId="left" type="monotone" dataKey="run_mode" stroke="#82ca9d" />) : null}
              {chartDisplay.run_status ? (<Line yAxisId="left" type="monotone" dataKey="run_status" stroke="#3c7692" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.temperature ? (<Line yAxisId="right" type="monotone" dataKey="temperature" stroke="#C06000" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.temperature_setpoint ? (<Line yAxisId="right" type="monotone" dataKey="temperature_setpoint" stroke="#8e67bf" />) : null}

              {chartDisplay.compare_fan_speed ? (<Line yAxisId="left" type="monotone" dataKey="compare_fan_speed" stroke="#f26d7d" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_run_mode ? (<Line yAxisId="left" type="monotone" dataKey="compare_run_mode" stroke="#548b72" />) : null}
              {chartDisplay.compare_run_status ? (<Line yAxisId="left" type="monotone" dataKey="compare_run_status" stroke="#b23a48" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_temperature ? (<Line yAxisId="right" type="monotone" dataKey="compare_temperature" stroke="#b2a300" activeDot={{ r: 8 }}/>) : null}
              {chartDisplay.compare_temperature_setpoint ? (<Line yAxisId="right" type="monotone" dataKey="compare_temperature_setpoint" stroke="#5c3d6f" />) : null}
            </LineChart>
          </ResponsiveContainer>
          );
        };
        const deviceNames: DeviceName = [
          {name:"ACU-00009", checked:true},
          {name:"ACU-00010", checked:false},
          {name:"ACU-00011", checked:false},
          {name:"ACU-00012", checked:false},
          {name:"ACU-00019", checked:false},
          {name:"ACU-00020", checked:false},
          {name:"ACU-00021", checked:false},
          {name:"ACU-00030", checked:false},
          {name:"ACU-00031", checked:false},
          {name:"ACU-00032", checked:false},
          {name:"ACU-00169", checked:false},
      ];

        return (
            <>
        {/* <Meter_Type_1_DeviceHolder /> */}
        <Container fluid>
        <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Air Conditioning Unit Graph Filter
                  </div>
                  {/* <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Header</Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Settings</span>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                </Card.Header>
                <Card.Body>
                  <AcuFilterData
                    deviceId={'ACU-00009'}
                    handleFilterData={this.handleFilterData}
                    deviceNames={deviceNames}
                    level={'Level-00'}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className="main-card mb-3">
                <Card.Body>
                  <Card.Title>Air Conditioning Unit Timeseries</Card.Title>
                 {RenderRechart(chartData, chartDisplay)}
                </Card.Body>
              </Card>
            </Col>
          </Row>  
          <Row>
            <Col>
              <Card className="mb-3">
                <Card.Header className="card-header-tab">
                  <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6"> {" "} </i>
                    Air Conditioning Unit Status
                  </div>
                  <div className="btn-actions-pane-right actions-icon-btn">
                  <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-menu btn-icon-wrapper" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu-right rm-pointers dropdown-menu-shadow dropdown-menu-hover-link">
                        <Dropdown.Item header>Actions</Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-inbox"> </i>
                          <span>Menus</span>
                        </Dropdown.Item> */}
                        <Dropdown.Item>
                          <i className="dropdown-icon lnr-file-empty"> </i>
                          <span>Generate Report</span>
                        </Dropdown.Item>
                        {/* <Dropdown.Item>
                          <i className="dropdown-icon lnr-book"> </i>
                          <span>Actions</span>
                        </Dropdown.Item>
                        <Dropdown.Item divider />
                        <div className="p-3 text-right">
                          <Button className="mr-2 btn-shadow btn-sm" color="link">
                            View Details
                          </Button>
                          <Button className="mr-2 btn-shadow btn-sm" color="primary">
                            Action
                          </Button>
                        </div> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Card.Header>
                <Card.Body>
                    <div className="-striped -highlight -fixed">
                  <ReactTable data={flattenedPointset}
                     columns={[
                      {
                        Header: "Air Conditioning Unit",
                        columns: [
                          {
                            Header: "Online",
                            id: "deviceOnline",
                            accessor: (row: ExtendedIAcuData) => row.online.present_value,
                            Cell: ({ value }: { value: boolean }) => (
                              <div style={{ textAlign: 'center' }}>
                                <span style={{ color: value ? 'green' : 'red', fontSize: '26px' }}>
                                  &#9679; 
                                </span>
                              </div>
                            ),
                            width: 100,
                          },
                          {
                            Header: "Name",
                            id: "deviceName",
                            accessor: (row: ExtendedIAcuData) => row.name.present_value,
                            width: 100,
                          },
                          {
                            Header: "Level",
                            id: "level",
                            accessor: (row: ExtendedIAcuData) => row.level.present_value,
                            width: 100,
                          },
                          {
                            Header: "Area",
                            id: "area",
                            accessor: (row: ExtendedIAcuData) => row.area.present_value,
                            width: 100,
                          },
                          {
                            Header: "Room",
                            id: "room",
                            accessor: (row: ExtendedIAcuData) => row.room.present_value,
                            width: 100,
                          },
                          // {
                          //   Header: "Alarm",
                          //   id: "ahuAlarm",
                          //   accessor: (row: ExtendedIMeterData) => row.alarm.present_value,
                          //   width: 100,
                          // }
                        ]
                      },
                      {
                        Header: "Last Seen",
                        columns: [
                          {
                            Header: "Timestamp",
                            id: "deviceTimestamp",
                            accessor: (row: ExtendedIAcuData) => row.timestamp.present_value,
                            width: 200,
                          },
                          {
                            Header: "Days",
                            id: "deviceLastSeenDays",
                            accessor: (row: ExtendedIAcuData) => row.last_seen_days.present_value,
                            width: 100,
                          },
                          {
                            Header: "Hours",
                            id: "deviceLastSeenHours",
                            accessor: (row: ExtendedIAcuData) => row.last_seen_hours.present_value,
                            width: 100,
                          },
                          {
                            Header: "Mins",
                            id: "deviceLastSeenMins",
                            accessor: (row: ExtendedIAcuData) => row.last_seen_mins.present_value,
                            width: 100,
                          },
                        ]
                      },
                      {
                        Header: "State",
                        columns: [
                          {
                            Header: "fan_speed",
                            id: "fan_speed",
                            accessor: (row: ExtendedIAcuData) =>
                              row.fan_speed?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "run_mode",
                            id: "run_mode",
                            accessor: (row: ExtendedIAcuData) =>
                              row.run_mode?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "run_status",
                            id: "run_status",
                            accessor: (row: ExtendedIAcuData) =>
                              row.run_status?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "temperature",
                            id: "temperature",
                            accessor: (row: ExtendedIAcuData) =>
                              row.temperature?.present_value || '-',
                            width: 120,
                          },
                          {
                            Header: "temperature_setpoint",
                            id: "temperature_setpoint",
                            accessor: (row: ExtendedIAcuData) =>
                              row.temperature_setpoint?.present_value || '-',
                            width: 120,
                          }
                        ],
                      },
                    ]}
                    />
                    </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container> 
            </>
    );
  }
}