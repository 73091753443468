import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";

import {ILuminaireData, ILuminaireMetaData, ExtendedILuminaireData} from '../../../firebase/types/luminaire.type';
import {DeviceName, LuminaireChartData, LuminaireChartDisplay} from '../../../redux/types/chartData.type';
import { createHumanTimestamp, isDeviceOnline, calculateLastSeen, secondsToIso } from '../../../utils/timestamp';
import LuminaireFilterData from '../../components/luminaireFilterData';
import LuminaireDataService from "../../../firebase/services/luminaire.service";
import {HistoryILuminaireData} from '../../../firebase/types/luminaire.type';
import { modeToNumber } from '../../../utils/acstatus';
import './modal.css';
import './buttonstyles.css';

interface LightControlModalProps {
  show: boolean;
  onHide: () => void;
  brightness: number;
  setBrightness: (value: number) => void;
  level:string;
  deviceIdSelected:string;
}

type State = {
  flattenedPointset: Array<ExtendedILuminaireData>,
  metadata: Array<ILuminaireMetaData>,
  brickschema: {},
  loading: false,
  device: [],
  chartData: LuminaireChartData;
  chartDisplay: LuminaireChartDisplay;
};

class LightControlModal extends Component<LightControlModalProps, State> {
  unsubscribe: () => void;

  componentDidUpdate(prevProps: LightControlModalProps) {
    // Check if deviceIdSelected has changed
    if (this.props.deviceIdSelected !== prevProps.deviceIdSelected) {
        this.runFirstDatabaseQuery(); // Call the function to fetch new data based on the new deviceIdSelected
    }
}

  componentDidMount() {
    // this.fetchTableData();
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
  }
  }

  constructor(props: LightControlModalProps) {
    super(props);
    
    this.state = {
      flattenedPointset: [],
      metadata: [],
      brickschema: {},
      loading: false,
      device: [],
      chartData:[
        { timestamp: "2022-09-16T00:30:00.000Z", 
          lighting_lamp_status: 1,
          brightness_percentage_command:1,
          max_limit_brightness_percentage_command: 1,
          initial_brightness_percentage_command:33.8,
          full_power_output: 25.2,
          electrical_power_sensor: 3,
          run_time_accumulator:3,
          compare_lighting_lamp_status: 3,
          compare_brightness_percentage_command:20.8,
          compare_max_limit_brightness_percentage_command: 25.2,
          compare_initial_brightness_percentage_command: 3,
          compare_full_power_output:20.8,
          compare_electrical_power_sensor: 25.2,
          compare_run_time_accumulator: 25.2
        },
        { timestamp: "2022-09-16T02:30:00.000Z", 
          lighting_lamp_status: 1,
          brightness_percentage_command:1,
          max_limit_brightness_percentage_command: 1,
          initial_brightness_percentage_command:33.8,
          full_power_output: 25.2,
          electrical_power_sensor: 3,
          run_time_accumulator:3,
          compare_lighting_lamp_status: 3,
          compare_brightness_percentage_command:20.8,
          compare_max_limit_brightness_percentage_command: 25.2,
          compare_initial_brightness_percentage_command: 3,
          compare_full_power_output:20.8,
          compare_electrical_power_sensor: 25.2,
          compare_run_time_accumulator: 25.2
        },
        { timestamp: "2022-09-16T04:30:00.000Z", 
          lighting_lamp_status: 1,
          brightness_percentage_command:1,
          max_limit_brightness_percentage_command: 1,
          initial_brightness_percentage_command:33.8,
          full_power_output: 25.2,
          electrical_power_sensor: 3,
          run_time_accumulator:3,
          compare_lighting_lamp_status: 3,
          compare_brightness_percentage_command:20.8,
          compare_max_limit_brightness_percentage_command: 25.2,
          compare_initial_brightness_percentage_command: 3,
          compare_full_power_output:20.8,
          compare_electrical_power_sensor: 25.2,
          compare_run_time_accumulator: 25.2
        },
        { timestamp: "2022-09-16T06:30:00.000Z", 
          lighting_lamp_status: 1,
          brightness_percentage_command:1,
          max_limit_brightness_percentage_command: 1,
          initial_brightness_percentage_command:33.8,
          full_power_output: 25.2,
          electrical_power_sensor: 3,
          run_time_accumulator:3,
          compare_lighting_lamp_status: 3,
          compare_brightness_percentage_command:20.8,
          compare_max_limit_brightness_percentage_command: 25.2,
          compare_initial_brightness_percentage_command: 3,
          compare_full_power_output:20.8,
          compare_electrical_power_sensor: 25.2,
          compare_run_time_accumulator: 25.2
        },
        { timestamp: "2022-09-16T08:30:00.000Z", 
          lighting_lamp_status: 1,
          brightness_percentage_command:1,
          max_limit_brightness_percentage_command: 1,
          initial_brightness_percentage_command:33.8,
          full_power_output: 25.2,
          electrical_power_sensor: 3,
          run_time_accumulator:3,
          compare_lighting_lamp_status: 3,
          compare_brightness_percentage_command:20.8,
          compare_max_limit_brightness_percentage_command: 25.2,
          compare_initial_brightness_percentage_command: 3,
          compare_full_power_output:20.8,
          compare_electrical_power_sensor: 25.2,
          compare_run_time_accumulator: 25.2
        },
      ],
      chartDisplay:{
        lighting_lamp_status: true,
        brightness_percentage_command: true,
        max_limit_brightness_percentage_command: true,
        initial_brightness_percentage_command: true,
        full_power_output: true,
        electrical_power_sensor: true,
        run_time_accumulator: true,
        compare_lighting_lamp_status: false,
        compare_brightness_percentage_command: false,
        compare_max_limit_brightness_percentage_command: false,
        compare_initial_brightness_percentage_command: false,
        compare_full_power_output: false,
        compare_electrical_power_sensor: false,
        compare_run_time_accumulator: false
      },
    };
    this.runFirstDatabaseQuery();
    this.unsubscribe = () => { };
  }

  flattenObjects(historyObjectArray: HistoryILuminaireData[]){
    let chartData: LuminaireChartData = historyObjectArray.map(item => ({
        timestamp: secondsToIso(item.timestamp.seconds),
        lighting_lamp_status: modeToNumber(item.points.lighting_lamp_status?.present_value) ?? 0,
        brightness_percentage_command: item.points.brightness_percentage_command?.present_value ?? 0,
        max_limit_brightness_percentage_command: item.points.max_limit_brightness_percentage_command?.present_value ?? 0,
        initial_brightness_percentage_command: item.points.initial_brightness_percentage_command?.present_value ?? 0,
        full_power_output: item.points.full_power_output?.present_value ?? 0,
        electrical_power_sensor: item.points.electrical_power_sensor?.present_value ?? 0,
        run_time_accumulator: item.points.run_time_accumulator?.present_value ?? 0,
        compare_lighting_lamp_status:  0,
        compare_brightness_percentage_command:  0,
        compare_max_limit_brightness_percentage_command:  0,
        compare_initial_brightness_percentage_command: 0,
        compare_full_power_output: 0,
        compare_electrical_power_sensor: 0,
        compare_run_time_accumulator: 0
      }));
    return chartData;
  };

  runFirstDatabaseQuery = async () => {  
    let queryResult: HistoryILuminaireData[] = [];
    // Set the end date to the current time
    const end = new Date();

    console.log("modal runFirstDatabaseQuery:" + this.props.deviceIdSelected)

    // Set the start date to 8 hours before the end date
    const start = new Date(end.getTime() - 24 * 60 * 60 * 1000);
    console.log('runFirstDatabaseQuery startDate:' + start + ' endDate:' + end);
    LuminaireDataService.getDeviceTimeseries(this.props.level, this.props.deviceIdSelected, start, end)
    .get()
    .then((querySnapshot: any) => {
        querySnapshot.forEach((doc:any) => {
            // doc.data() is never undefined for query doc snapshots
            // console.log(doc.id, " => ", doc.data());
            queryResult.push(doc.data());
        });
    }).then(()=> {
        console.log("runFirstDatabaseQueryResult.length:"+ queryResult.length)
        this.setState({ chartData: this.flattenObjects(queryResult) }, () => {
          // this.handleFilterData();
      });
    }).catch((error) => {
        console.log("Error getting documents: ", error);
    });

  };

  render() {
    const { show, onHide, brightness, setBrightness } = this.props;
    const { chartData, chartDisplay} = this.state;

    const calculateMaxYValue = (chartData:LuminaireChartData, chartDisplay:LuminaireChartDisplay) => {
      if (!Array.isArray(chartData) || chartData.length === 0) {
        return 100;
      }

      return Math.max(
        ...chartData.map(d => Math.max(
          chartDisplay.lighting_lamp_status ? d.lighting_lamp_status ?? 0 : 0,
          chartDisplay.brightness_percentage_command ? d.brightness_percentage_command ?? 0 : 0,
          chartDisplay.max_limit_brightness_percentage_command ? d.max_limit_brightness_percentage_command ?? 0 : 0,
          chartDisplay.initial_brightness_percentage_command ? d.initial_brightness_percentage_command ?? 0 : 0,
          chartDisplay.full_power_output ? d.full_power_output ?? 0 : 0,
          chartDisplay.electrical_power_sensor ? d.electrical_power_sensor ?? 0 : 0,
          chartDisplay.run_time_accumulator ? d.run_time_accumulator ?? 0 : 0,
          chartDisplay.compare_lighting_lamp_status ? d.compare_lighting_lamp_status ?? 0 : 0,
          chartDisplay.compare_brightness_percentage_command ? d.compare_brightness_percentage_command ?? 0 : 0,
          chartDisplay.compare_max_limit_brightness_percentage_command ? d.compare_max_limit_brightness_percentage_command ?? 0 : 0,
          chartDisplay.compare_initial_brightness_percentage_command ? d.compare_initial_brightness_percentage_command ?? 0 : 0,
          chartDisplay.compare_full_power_output ? d.compare_full_power_output ?? 0 : 0,
          chartDisplay.compare_electrical_power_sensor ? d.compare_electrical_power_sensor ?? 0 : 0,
          chartDisplay.compare_run_time_accumulator ? d.compare_run_time_accumulator ?? 0 : 0
        ))
      );
    };

    const RenderRechart = (chartData:LuminaireChartData, chartDisplay:LuminaireChartDisplay) => {
      return(
        <ResponsiveContainer width="100%" height={400}>
        <LineChart data={chartData} width={500} height={400} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <XAxis 
            dataKey="timestamp" 
            tickFormatter={createHumanTimestamp}
            // ticks={this.calculateTicks(chartData, 7)}
            angle={-45} 
            textAnchor="end"
            height={120}
          />
          <YAxis label={{ value: "%", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} interval={0} domain={[0, calculateMaxYValue]}/> 
          {/* <YAxis label={{ value: "kWh", position: "insideLeft", angle: 0,   dy: -10}} yAxisId="left" tickLine={false} axisLine={true} domain={[0, 4000]} interval={0} ticks={[0, 800, 1600, 2400, 3200, 4000]}/>  */}
          <YAxis label={{ value: "A", position: "insideRight", angle: 0,   dy: -10}} yAxisId="right" orientation='right' tickLine={false} axisLine={true} domain={[0, 15000]} interval={0}/>
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip />
          <Legend />
          {chartDisplay.lighting_lamp_status ? (<Line yAxisId="left" type="monotone" dataKey="lighting_lamp_status" stroke="#6fa8dc" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.brightness_percentage_command ? (<Line yAxisId="left" type="monotone" dataKey="brightness_percentage_command" stroke="#82ca9d" />) : null}
          {chartDisplay.max_limit_brightness_percentage_command ? (<Line yAxisId="left" type="monotone" dataKey="max_limit_brightness_percentage_command" stroke="#3c7692" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.initial_brightness_percentage_command ? (<Line yAxisId="left" type="monotone" dataKey="initial_brightness_percentage_command" stroke="#C06000" activeDot={{ r: 8 }}/>) : null}
          {chartDisplay.full_power_output ? (<Line yAxisId="left" type="monotone" dataKey="full_power_output" stroke="#8e67bf" />) : null}
          {chartDisplay.electrical_power_sensor ? (<Line yAxisId="left" type="monotone" dataKey="electrical_power_sensor" stroke="#8B0000" />) : null}
          {chartDisplay.run_time_accumulator ? (<Line yAxisId="right" type="monotone" dataKey="run_time_accumulator" stroke="#FF69B4" />) : null}
        </LineChart>
      </ResponsiveContainer>
      );
    };

    const isLightDevice = this.props.deviceIdSelected.startsWith("LT");

    return (
      <Modal
        show={show}
        onHide={onHide}
        backdrop={false}
        dialogClassName="rightAlignedModal" 
        centered 
        backdropClassName="modal-backdrop"
      >
        <Modal.Header closeButton className="modal-header-custom">
          <Modal.Title>{this.props.deviceIdSelected}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body-custom">
        <p>Adjust the {isLightDevice ? 'brightness' : 'temperature:'} for {isLightDevice ? 'light' : 'air conditioning'}</p>
          <Form>
            <Form.Group>
              <Form.Label>{isLightDevice ? 'Brightness:' : 'Temperature:'} {brightness} {isLightDevice ? '%' : '°C'}</Form.Label>
              <Form.Control
                type="range"
                min="0"
                max="100"
                value={brightness}
                onChange={e => setBrightness(Number(e.target.value))}
              />
            </Form.Group>
          </Form>
          <div style={{ minHeight: '500px' }}>
            {RenderRechart(chartData, chartDisplay)}
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer-custom">
          <Button variant="secondary" onClick={onHide}>Close</Button>
          <Button variant="primary" onClick={() => console.log('Brightness set to:', brightness)}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default LightControlModal;