import React, { Fragment } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PageTitle from "../../../layout/AppMain/PageTitle";

import Tabs from 'rc-tabs';
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane';

import { connect, ConnectedProps } from 'react-redux';
import { StoreState } from '../../../redux/types/store.type';
import { BuildingState } from '../../../redux/types/building.type';

import AnalyticsDashboard1 from "./TypePages/Variation1";

import {ThemeOptionsState} from "../../../../components/redux/types/themeOptions.type";

interface OwnProps {
    name?: string;
}

type MapStateToPropsType = {
    ThemeOptions: ThemeOptionsState;
    Buildings: BuildingState;
};

type Props = ConnectedProps<typeof connector> & OwnProps & MapStateToPropsType;

const NETVOX3PHASEDashboard: React.FC<Props> = ({Buildings}) => {

    return (
        <>
            <TransitionGroup component="div">
                <CSSTransition
                    classNames="TabsAnimation"
                    timeout={{
                    appear: 0,
                    enter: 0,
                    exit: 0,
                    }}
                    appear={true}
                    enter={false}
                    exit={false}
                >
                    <div>
                        <PageTitle heading="Metering Electric"
                        subheading="Electrical metering devices play a pivotal role in monitoring and documenting a building's energy consumption. These meters, equipped with sensors and communication interfaces, measure the real-time electrical usage of various circuits, systems, or the entire facility. By transmitting this data to the BMS, stakeholders can analyze consumption patterns, identify inefficiencies, and optimize energy use. The primary purpose of electrical metering is not only to provide accurate billing data but also to promote sustainable and efficient energy practices by offering insights into consumption trends and potential areas for energy-saving interventions within the building's operational framework."
                        icon="pe-7s-cloud icon-gradient bg-mean-fruit"/>
                        {/* <Tabs defaultActiveKey="1" onChange={callback}>
                            <TabPane tab="Type 1" key="1"> */}
                                <AnalyticsDashboard1 buildings={Buildings}/>
                            {/* </TabPane>
                        </Tabs> */}
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </>
    );
};

// // const mapStateToProps = (state: StoreState.All): ArticleState => {
// //     return {
// //       state: state
// //     };
// //   };

const mapStateToProps = (state: StoreState.All): MapStateToPropsType => {
    return {
        ThemeOptions: state.ThemeOptions,
        Buildings: state.Buildings
    };
};

const connector = connect(mapStateToProps);
  
export default connector((props: Props) => {

    return <NETVOX3PHASEDashboard {...props} />;
});